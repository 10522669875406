<template>
  <div>
    <div class="staff">
      <div class="left-top">
        <span style="font-size: 18px; font-weight: 400; color: #333"
          >版本列表</span
        >
        <!-- <el-button type="primary">提交</el-button> -->
        <el-divider></el-divider>
        <el-button
          type="primary" @click="addPage"
          style="float: left; margin: 10px 5px; padding: 15px 20px"
          >
          <i class="el-icon-circle-plus-outline" style="margin-right: 5px"></i>
          添加版本
        </el-button>
      <el-form
        :model="searchForm"
        :inline="true"
        ref="searchForm"
        class="searchForm"
      >
        <el-form-item label="端口:">
          <el-select
            v-model="searchPartValue"
            style="width: 180px"
            @change="searchPart"
          >
            <el-option
              v-for="item in AllSearchPart"
              :key="item.id"
              :value="item.value"
              :label="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="keysSearch(1)">
            <i class="el-icon-search" style="margin-right: 5px"></i>
            搜索
          </el-button>
          <el-button @click="resetForm()">
            <i class="el-icon-refresh-right" style="margin-right: 5px"></i>
            重置
          </el-button>
        </el-form-item>
      </el-form>
      </div>
      <el-table
        border
        :data="PageConfigure"
        row-key="CategoryGuid"
        style="width: 100%"
      >
        <el-table-column
          fixed
          label="序号"
          width="100"
          type="index"
          align="center"
        >
        </el-table-column>
         <el-table-column
          label="端口"
          width="150"
          prop="Part"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.Part == 0">IOS</span>
            <span v-if="scope.row.Part == 1">Android</span>
          </template>
        </el-table-column>
        <el-table-column
          label="版本号"
          width="150"
          prop="Edition"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="更新内容"
          min-width="200"
          prop="contenttext"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="上线时间"
          width="180"
          prop="OnlineTime"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="强制更新"
          width="180"
          prop="force"
          align="center"
        >
        </el-table-column>
        <el-table-column fixed="right" width="300" label="操作" align="center">
          <template slot-scope="scope">
            <span
              class="backSpan"
              @click="eduit(scope.row)"
              style="color: #409eff"
            >
              查看
            </span>
            <span
              class="backSpan"
              @click="PreDelet(scope.row)"
              style="color: #cc0000"
            >
              删除
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="task-list-page" v-if="PageConfigure !== ''">
        <el-pagination
          @current-change="OnCurrentPageChanged"
          @size-change="handleSizeChange"
          :current-page="Params.PageIndex"
          :page-sizes="[10, 20, 30, 40, 50, 60]"
          layout="prev, pager, next,sizes,jumper,total"
          :page-size="Params.PageSize"
          background
          :total="Params.TotalCount"
        >
        </el-pagination>
      </div>

      <!-- 编辑 -->
      <div class="formdia">
        <el-dialog
          :visible.sync="dialogFormVisible"
          custom-class="edit-form"
          :title="dialongtitle"
          :modal-append-to-body="false"
        >
          <el-form :model="Rowcontent" :rules="rules" ref="editForm">
            <el-form-item
              label="版本号:"
              :label-width="formLabelWidth"
              prop="Edition"
            >
               <el-input
                style="width: 340px"
                v-model="Rowcontent.Edition"
                placeholder="请输入版本号"
                maxlength="40"
              ></el-input>
            </el-form-item>
            <el-form-item
             label="端口:"
             :label-width="formLabelWidth"
             prop="Part">
              <el-select
                style="width: 340px"
                v-model="partValue"
                @change="selPart"
                filterable
                placeholder="请选择对应端口"
              >
                <el-option
                  v-for="item in AllPart"
                  :key="item.id"
                  :label="item.value"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="上线时间:"
              :label-width="formLabelWidth"
              prop="OnlineTime"
            >
              <el-date-picker
                v-model="Rowcontent.OnlineTime"
                @change="changetime"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              label="强制更新:"
              prop="radio"
              :label-width="formLabelWidth"
            >
              <el-radio
                v-model="Rowcontent.radio"
                @change="changeradio0"
                label="0"
                >是</el-radio
              >
              <el-radio
                v-model="Rowcontent.radio"
                @change="changeradio1"
                label="1"
                >否</el-radio
              >
            </el-form-item>
            <!-- <el-form-item
                label="更新内容:"
                :label-width="formLabelWidth"
                prop="Content"
                >
                 <el-input
                    style="width:340px"
                    type="textarea"
                    :rows="5"
                    maxlength="200"
                    v-model="Rowcontent.Content"
                    placeholder="请输入更新内容"
                ></el-input>
                </el-form-item> -->
            <el-form-item
              label="更新内容:"
              prop="Content"
              :label-width="formLabelWidth"
            >
              <div class="edit_container">
                <quill-editor
                  v-model="Rowcontent.Content"
                  ref="myQuillEditor"
                  :options="editorOption"
                  @blur="onEditorBlur($event)"
                  @focus="onEditorFocus($event)"
                  @change="onEditorChange($event)"
                >
                </quill-editor>
              </div>
            </el-form-item>
          </el-form>
              
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">返回</el-button>
            <el-button
              v-if="addpage"
              @click="submitForm('editForm')"
              :disabled="isDisabled"
            >
              确定
            </el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import Sortable from "sortablejs";
import { quillEditor } from "vue-quill-editor"; //调用编辑器
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  components: {
    quillEditor,
  },

  data() {
    return {
      dialogFormVisible: false,
      formLabelWidth: "100px",
      Rowcontent: {
        EditionId: "",
        Edition: "",
        Content: "",
        OnlineTime: "",
        IsRefash: false,
        IsDelete: false,
        force: "",
        radio: "",
        Part:"",
      },
      rules: {
        Edition: [{ required: true, message: "请完善信息", trigger: "blur" }],
        OnlineTime: [
          {
            required: true,
            message: "请填写时间",
            trigger: ["blur", "change"],
          },
        ],
        radio: [
          { required: true, message: "请选择是否强制更新", trigger: "change" },
        ],
        Content: [{ required: true, message: "请完善信息", trigger: "blur" }],
        Part:[{required: true, message: "请完善信息", trigger: "blur"}],
      },
      PageConfigure: [], //列表分页数据
      Params: {
        PageIndex: 1,
        PageSize: 10,
        TotalCount: 5,
        TotalPage: 0,
      },
      IsApp: false,
      IsEdit: false,
      pageName: "",
      deleteStatus: false,
      //选择日期
      pickerOptions: {
        disabledDate(time) {
          return time.getDate();
        },
      },
      dialongtitle: "新增版本",
      addpage: false,
      //解决重复点击问题
      isDisabled: false,
      //新增的时候 端口选择
      AllPart:[
        {
          id:0,
          value:"IOS",
        },
        {
          id:1,
          value:"Android",
        }
      ],
      partValue:'',
      //查询的时候 端口选择
      searchForm:{},
      AllSearchPart:[
        {
          id:-1,
          value:"全部",
        },
        {
          id:0,
          value:"IOS",
        },
        {
          id:1,
          value:"Android",
        }
      ],
      searchPartValue:'全部',
      //富文本编辑器
      editorOption: {
        modules: {
          toolbar: [
            // [{ 'size': ['small',false, 'large', 'huge'] }],//字体大小
            [{ color: [] }], //字体颜色
            [{ align: [] }],
          ],
        },
      },
      str: "",
    };
  },
  created() {
    
  },
  mounted() {
    this.searchDate();
  },

  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },

  methods: {
    changetime() {
      let addtime = this.Rowcontent.OnlineTime;
      let newtime = addtime.setHours(addtime.getHours() + 8);
      this.Rowcontent.OnlineTime = new Date(newtime);
      console.log(this.Rowcontent.OnlineTime);
    },

    //转码
    escapeStringHTML(str) {
      var s = "";
      if (str.length == 0) return "";
      s = str.replace(/\<.*?\>/g, "");
      return s;
    },

    //获取数据
    searchDate() {
      var _this = this;
      this.$get(_this.$api.EditionGetList, { ...this.Params })
        .then((res) => {
          console.log(res.PageIndex);
          console.log(res);
          _this.PageConfigure = res.Data;
          _this.PageConfigure.forEach((item, index)=>{
            item.OnlineTime = item.OnlineTime.substring(0, 10);
            let content = item.Content;
            _this.str = _this.escapeStringHTML(content);
            item.contenttext = _this.str;
            if (item.contenttext.length > 30) {
              _this.PageConfigure[index].contenttext =
                item.contenttext.substring(0, 30) + "...";
            } else {
              _this.PageConfigure[index].contenttext = item.contenttext;
            }
            if (item.IsRefash == false) {
              item.force = "否";
            } else {
              item.force = "是";
            }
          });
          _this.Params.TotalCount = res.TotalNumber;
          _this.Params.TotalPage = res.TotalPage;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //单选框
    changeradio0() {
      this.Rowcontent.IsRefash = "true";
    },
    changeradio1() {
      this.Rowcontent.IsRefash = "false";
    },

    //新增
    addPage() {
      this.addpage = true;
      this.dialongtitle = "新增版本";
      this.IsEdit = false;
      this.dialogFormVisible = true;
      this.Rowcontent.EditionId = "";
      this.Rowcontent.Edition = "";
      this.Rowcontent.Content = "";
      this.Rowcontent.OnlineTime = "";
      this.Rowcontent.Part = "";
      this.Rowcontent.radio = "1";
      this.Rowcontent.IsRefash = "false";
    },
    //查看
    eduit(val) {
      this.addpage = false;
      var _this = this;
      this.dialongtitle = "[" + val.Edition + "]";
      this.IsEdit = true;
      this.dialogFormVisible = true;
      this.Rowcontent.EditionId = val.EditionId;
      this.Rowcontent.Edition = val.Edition;
      this.Rowcontent.Content = val.Content;
      console.log(this.Rowcontent.Content);
      this.Rowcontent.OnlineTime = val.OnlineTime;
      this.Rowcontent.IsRefash = val.IsRefash;
      this.AllPart.forEach(function (item, index) {
        if (val.Part == item.id) {
          _this.partValue = item.value;
        }
      });
      if (val.IsRefash == false) {
        this.Rowcontent.radio = "1";
      } else {
        this.Rowcontent.radio = "0";
      }
      this.Rowcontent.IsDelete = val.IsDelete;
    },

    //富文本编辑器
    onEditorReady(editor) {
      // 准备编辑器
    },
    onEditorBlur() {}, // 失去焦点事件
    onEditorFocus() {}, // 获得焦点事件
    onEditorChange() {}, // 内容改变事件

    //新增、编辑的提交
    entime() {
      setTimeout(() => {
        this.isDisabled = false;
      }, 500);
    },

    submitForm(editForm) {
      var _this = this;
      this.isDisabled = true;
      this.entime();
      this.$refs[editForm].validate((valid) => {
        if (valid) {
          if (this.IsEdit) {
            //编辑或者是查看
            console.log("编辑");
            console.log(this.Rowcontent);
            this.$post(this.$api.UpdateArticleCategory, this.Rowcontent)
              .then((res) => {
                if (res.IsSuccess) {
                  _this.$message({
                    message: "编辑成功",
                    type: "success",
                    duration: "1500",
                  });
                  _this.searchDate();
                }
              })
              .catch((err) => {
                console.log(err);
              });
            this.dialogFormVisible = false;
          } else {
            //新增
            console.log("新增");
            this.$post(this.$api.EditionAddModel, this.Rowcontent)
              .then((res) => {
                if (res.IsSuccess) {
                  _this.$message({
                    message: "新增成功",
                    type: "success",
                    duration: "1500",
                  });
                  _this.searchDate();
                }
              })
              .catch((err) => {
                console.log(err);
              });
            this.dialogFormVisible = false;
          }
        } else {
          this.$message({
            message: "请完善信息",
            type: "warning",
            duration: "1500",
          });
        }
      });
    },

    //获取分页
    OnCurrentPageChanged(val) {
      this.Params.PageIndex = val;
      console.log(this.Params.PageIndex);
      this.keysSearch();
    },
    handleSizeChange(val) {
      this.Params.PageSize = val;
      console.log(val);
      this.keysSearch();
    },

    //删除
    PreDelet(row, index, data) {
      console.log(row);
      this.$confirm("是否确认删除版本" + "[" + row.Edition + "]", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        iconClass: "el-icon-question",
      })
        .then(() => {
          this.$post(this.$api.EditionDeleteModel + "?id=" + row.EditionId)
            .then((res) => {
              if (res.IsSuccess) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.searchDate();
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => console.log(err));
    },
    //新增的时候
    selPart() {
      var _this = this;
      this.AllPart.forEach(function (item, index) {
        if (item.value == _this.partValue) {
          _this.Rowcontent.Part = item.id;
        }
      });
    },
    //查询的时候
    searchPart(){
      var _this = this;
      this.AllSearchPart.forEach(function (item, index) {
        //console.log(item.value == _this.searchPartValue)
        if (item.value == _this.searchPartValue) {
          _this.searchForm.Part = item.id;
        }
      });
    },
    //查询
    keysSearch(num) {
      var _this = this;
      if (num == 1) {
        this.Params.PageIndex = 1;
      }
      this.$get(this.$api.EditionGetList, {
        ...this.searchForm,
        ...this.Params,
      }).then((res) => {
          _this.PageConfigure = res.Data;
          _this.PageConfigure.forEach((item, index)=>{
            item.OnlineTime = item.OnlineTime.substring(0, 10);
            let content = item.Content;
            _this.str = _this.escapeStringHTML(content);
            item.contenttext = _this.str;
            if (item.contenttext.length > 30) {
              _this.PageConfigure[index].contenttext =
                item.contenttext.substring(0, 30) + "...";
            } else {
              _this.PageConfigure[index].contenttext = item.contenttext;
            }
            if (item.IsRefash == false) {
              item.force = "否";
            } else {
              item.force = "是";
            }
          });
        _this.searchForm = {};
        _this.Params.TotalCount = res.TotalNumber;
        _this.Params.TotalPage = res.TotalPage;
      });
    },
    //重置查询
    resetForm() {
      this.searchPartValue = "全部";
      this.Params.PageIndex = 1;
      this.searchDate();
    },
  },
};
</script>

<style lang="stylus" scoped>
/deep/.is-center.is-leaf {
  background: #e6e6e6;
  color: #303133;
}

/deep/.has-gutter tr {
  th {
    background: #e6e6e6;
    color: #303133;
  }

  td {
    text-align: center;
  }
}

.staff {
  background: #fff;
  padding: 10px 10px;
  border-radius: 10px;

  .searchForm {
    margin-left: 150px;

    .el-form-item {
      vertical-align: middle;
      margin-top: 13px;
      margin-right: 30px;
    }
  }

  .backSpan {
    padding: 0px 20px;
    cursor: pointer;
  }

  .left-top {
    margin-bottom: 10px;

    .el-divider--horizontal {
      margin: 10px 0;
    }

    button {
      margin-left: 10px;
    }
  }

  .el-table .cell span {
    overflow: hidden; /* 超出部分隐藏 */
    white-space: nowrap; /* 不换行 */
    text-overflow: ellipsis; /* 超出部分文字以...显示 */
  }

  .task-list-page {
    margin-top: 10px;
  }
}

/* 编辑框样式 */
/deep/.edit-form {
  span.isedit {
    position: absolute;
    top: 10px;
    left: 185px;
    font-size: 22px;
    color: #fff;
  }

  width: 500px;

  .el-dialog__header {
    height: 50px;
    text-align: center;
    background-color: #409eff;

    .el-dialog__title {
      font-size: 22px;
      color: #fff;
      line-height: 0px;
      text-align: center;
    }

    .el-dialog__headerbtn {
      display: none;
    }
  }

  .el-form-item .el-textarea__inner {
    resize: none;
  }

  .el-dialog__body {
    padding-bottom: 5px !important;
  }

  .dialog-footer {
    text-align: center;

    .el-button {
      background-color: #fff;
      color: #606266;
      border: 1px solid #dcdfe6;
    }

    .el-button:hover {
      background-color: #409eff;
      border-color: #409eff;
      color: #fff;
    }
  }
}
</style>